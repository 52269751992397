import axios from "axios";
import { useEffect, useState } from "react";
import styles from "./App.module.scss";
import {useRouteMatch } from "react-router-dom";
import {AddressIcon, ErrorIcon, InstIcon, Logo, SiteIcon, VkIcon} from "./components/icons";

function App() {
  let [cooking, setCooking] = useState([]);
  let [ready, setReady] = useState([]);
  let [errorText, setErrorText] = useState('');
  let [link_inst, setLink_inst] = useState("");
  let [link_vk, setLink_vk] = useState("");
  let [name, setName] = useState(""); //Объявление основных переменных

  
  let rest_id = "";
  let match = useRouteMatch('/:rest_id');
  if(match && match.isExact){
    rest_id = match.params.rest_id;
  }


  function getInfo(rest_id) {
    axios.get("https://controlling.sushifuji.ru/client_dashboard/~" + rest_id).then((response) => {
      if (response.data.error === 0) {
        setCooking(response.data.cooking);
        setReady(response.data.ready);
        setLink_inst(response.data.city.link_inst.replace(/(^\w+:|^)\/\//, ""));
        setLink_vk(response.data.city.link_vk.replace(/(^\w+:|^)\/\//, ""));
        setName(response.data.name);
        setErrorText('');
      } else {
        setErrorText(response.data.error_text);
      }
    }).catch(() => {
        setErrorText("Нет соединения с сайтом");
    });
  }

  useEffect(() => {
    getInfo(rest_id);
    let interval = setInterval(() => {
      getInfo(rest_id);
    }, 10000);
    return () => {
      clearInterval(interval);
    };

  }, [rest_id]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.left_col}>
          <div className={styles.header}>Готовятся</div>
          <ListOrders orders={cooking} />
        </div>
        <div className={styles.right_col}>
          <div className={styles.header}>Готовы</div>
          <ListOrders orders={ready} />
        </div>
      </div>

      <Logo />
      <div className={styles.href_block}>
        <div>
          <SiteIcon />
          sushifuji.ru
        </div>
        {link_vk && (
          <div>
            <VkIcon />
            {link_vk}
          </div>
        )}

        {link_inst && (
          <div>
            <InstIcon />
            {link_inst}
          </div>
        )}

        {name && (
            <div>
              <AddressIcon />
              {name}
            </div>
        )}

        {errorText && (
            <div><ErrorIcon />{errorText}</div>
        )}
      </div>
    </>
  );
};

function ListOrders(props) {
  return (
    <div className={styles.list_orders}>
      {props.orders.map((el) =>

        el.number.length > 0 &&
        el.name.length > 0 &&
          <div key={el.number}>
            {el.name.split(" ").length === 1 ? el.name : el.name.split(" ")[0] + " " + el.name.split(" ")[1][0] + "."}{" "}
            {el.number}
          </div>
      )}
    </div>
  );
}

export default App;
